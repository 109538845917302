import React from "react";
import { graphql } from "gatsby";
import { withPrefix } from "gatsby-link";

import Fade from "react-reveal/Fade";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import WereSocial from "../components/WereSocial";

import { RiArrowRightUpLine } from "react-icons/ri";
import { FiCalendar } from "react-icons/fi";

// date-fns
import { format as dateFormat } from "date-fns";

class News extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    const allPress = data.allPress.edges;
    const allEvents = data.allEvents.edges;
    const allBusinessNews = data.allBusinessNews.edges;

    return (
      <Layout
        HeaderExtension={
          <div className="header_extension no_bg">
            <div className="bg">
              <div className="container aligncenter">
                <div className="columns post-single ui-grid">
                  <div className="column is-8">
                    <Fade bottom duration={1000} delay={125} distance={"25%"}>
                      <h1 className="primary heading_lg aligncenter">
                        <span>Media & Press</span>
                      </h1>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        location={this.props.location}
        title={siteTitle}
      >
        <SEO
          title="News"
          keywords={[
            "Latest news, Upper Canada Soap, Personal care, Personal care products, Beauty brands, Natural skincare, Skincare products, Skincare, Danielle Beauty, Danielle Mirrors, Therawell, Wanderflower, Studio Dry",
          ]}
          description="Get the latest news from Upper Canada Soap, a world leading distributor of personal care products, beauty brands and natural skincare products including Danielle Beauty, Danielle Mirrors,Therawell, Wanderflower and Studio Dry."
          image={null}
        />

        <div>
          <div className="pages-index pages-news">
            <div className="section-press">
              <div className="container">
                <h2>Our brands in the news</h2>

                <div className="columns is-multiline">
                  {allPress.map(({ node }) => {
                    const image = getImage(node.frontmatter.featuredImage);

                    return (
                      <Fade key={node.fields.slug} duration={300} delay={350}>
                        <div to={node.fields.slug} className="column is-4">
                          <div className="cp-card">
                            <a
                              href={node.frontmatter.url}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <div className="cp-image">
                                <GatsbyImage
                                  image={image}
                                  alt={node.frontmatter.title}
                                />

                                <svg
                                  viewBox="0 0 1200 800"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect width="1200" height="800" fill="#FFF" />
                                </svg>
                              </div>

                              <p className="cp-source">
                                <span>{node.frontmatter.source}</span>
                              </p>

                              <p className="cp-title">
                                <span>{node.frontmatter.title}</span>
                              </p>

                              <p className="cp-date">
                                <span>
                                  {dateFormat(
                                    new Date(node.frontmatter.date),
                                    "MMM dd yyyy"
                                  )}
                                </span>
                              </p>

                              <p className="cp-link">
                                <span>
                                  <a
                                    href={node.frontmatter.url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    Read more <RiArrowRightUpLine />
                                  </a>
                                </span>
                              </p>
                            </a>
                          </div>
                        </div>
                      </Fade>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="section-business-news">
              <div className="container">
                <h2>Business related news</h2>

                {allBusinessNews.map(({ node }) => (
                  <Fade key={node.fields.slug} duration={300} delay={350}>
                    <div>
                      <a
                        href={withPrefix(node.frontmatter.pdf)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="cp-item columns is-vcentered"
                      >
                        <p className="column is-8 cp-title">
                          <span>{node.frontmatter.title}</span>
                        </p>

                        <p className="column is-2 cp-date">
                          <span>
                            {dateFormat(
                              new Date(node.frontmatter.news_date),
                              "MMM dd yyyy"
                            )}
                          </span>
                        </p>

                        <p className="column is-2 cp-link">
                          <span>
                            Read more <RiArrowRightUpLine />
                          </span>
                        </p>
                      </a>
                    </div>
                  </Fade>
                ))}
              </div>
            </div>

            <div className="section-events">
              <div className="container">
                <h2>Upcoming events</h2>

                <div className="columns is-vcentered is-multiline">
                  {allEvents.map(({ node }) => (
                    <Fade key={node.fields.slug} duration={300} delay={350}>
                      <div to={node.fields.slug} className="column is-half">
                        <a
                          href={node.frontmatter.url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {" "}
                          <div className="cp-card">
                            <p className="cp-title">
                              <span>{node.frontmatter.title}</span>
                            </p>
                            <p className="cp-date">
                              <span>
                                <FiCalendar />
                                {node.frontmatter.event_date}
                              </span>
                            </p>
                            <p className="cp-link">
                              <span>
                                Read more <RiArrowRightUpLine />
                              </span>
                            </p>
                          </div>
                        </a>
                      </div>
                    </Fade>
                  ))}
                </div>
              </div>
            </div>

            <WereSocial />
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allPress: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "press" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            source
            title
            url
          }
        }
      }
    }

    allEvents: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "events" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            event_date
            title
            url
          }
        }
      }
    }

    allBusinessNews: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "business_news" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            news_date
            title
            pdf
          }
        }
      }
    }
  }
`;
