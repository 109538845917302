import React from "react";

import { FaInstagram } from "react-icons/fa";

import { StaticImage } from "gatsby-plugin-image";

import PROFILE_DANIELLE from "../img/profile_pictures/social_danielle_creations.png";
import PROFILE_ERASE from "../img/profile_pictures/social_erase_your_face.png";
import PROFILE_UPPER_CANADA from "../img/profile_pictures/social_upper_canada.png";
import PROFILE_WANDERFLOWER from "../img/profile_pictures/social_wanderflower.png";
import PROFILE_YES from "../img/profile_pictures/social_yes_studio.png";
import PROFILE_AROMAHOME from "../img/profile_pictures/social_aroma.jpg";

const ITEMS = [
  {
    name: "Upper Canada Soap",
    handle: "uppercanadasoap_",
    img: PROFILE_UPPER_CANADA
  },
  {
    name: "Erase Your Face",
    handle: "eraseyourfaceco",
    img: PROFILE_ERASE
  },
  {
    name: "Yes Studio",
    handle: "yesstudiodesigns",
    img: PROFILE_YES
  },
  {
    name: "Danielle Creations",
    handle: "daniellebeautyuk",
    img: PROFILE_DANIELLE
  },
  {
    name: "Wanderflower",
    handle: "by_wanderflower",
    img: PROFILE_WANDERFLOWER
  },
  {
    name: "Aroma Home",
    handle: "aromahome_uk",
    img: PROFILE_AROMAHOME
  }
];

const WereSocial = () => (
  <div className="cp-were-social">
    <div className="container">
      <h2>We’re social</h2>

      <div className="columns is-multiline">
        {ITEMS.map((item) => (
          <div className="column is-4">
            <div className="columns is-vcentered is-mobile">
              <div className="column is-narrow">
                <img src={item.img} alt={item.name} />
              </div>

              <div className="column is-narrow">
                <p className="cp-title">{item.name}</p>

                <p className="cp-link">
                  <a
                    href={`https://instagram.com/${item.handle}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FaInstagram /> @{item.handle}
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default WereSocial;
